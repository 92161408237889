/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import { StaticImage } from 'gatsby-plugin-image'

import {
  Banner,
  Columns,
  Column,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi()

  const brandyList = ['disclaimer-aet-directcampaignlp']

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title: ' Aetna Medicare ',
          description: '',
          canonical: 'https://www.aetnamedicaredirect.com/',
          robots: 'follow,index',
        },
        path: '/lp/split',
        promoCode: 'SAMPLEPROMO',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://www.aetnamedicaredirect.com/',
          siteName: 'aetnamedicaredirect',
          alternateName: 'aetnamedicaredirect',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'Aetna Medicare ',
    defaultRobots: 'nofollow,noindex',
    main: (
      <>
        <VariableContent
          backgroundColor="dark"
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2" color="light">
                Access affordable Aetna Medicare Advantage plans in your area
              </Typography>
              <Typography variant="h4" color="light">
                Try our comparison tool!
              </Typography>
              <Typography variant="body" color="light">
                Aetna Medicare Advantage plans with premiums as low as $0/mo may
                be available in your area.
              </Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-laptop.svg"
                    alt="laptop icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    ONLINE
                  </Typography>
                  <List>
                    <ListItem>Select button to “View Plan Pricing”.</ListItem>
                    <ListItem>Enter Your Information.</ListItem>
                    <ListItem>Review Plan Options.</ListItem>
                  </List>
                </div>
                <br />
                <LinkButton
                  variant="feature"
                  to="https://www.sunfirematrix.com/app/consumer/clearlink/18572496/"
                  color="primary"
                  className="margin-x-auto"
                >
                  View Plan Pricing
                </LinkButton>
                <br />
                <Typography variant="body" color="dark">
                  Get Your Quote. No Obligation to Enroll.
                </Typography>
              </Column>
              <Column className="card center-content bg-light-gray">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-mobile-phone.svg"
                    alt="phone icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5" color="dark">
                    ON THE PHONE
                  </Typography>
                  <List>
                    <ListItem>
                      Call to speak with a Licensed Sales Agent.
                    </ListItem>
                    <ListItem>
                      Our agents gather your health information and find plans
                      in your area that meet your needs.
                    </ListItem>
                    <ListItem>
                      Work with the agent to compare plans and make the right
                      choice.
                    </ListItem>
                  </List>
                </div>
                <br />
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className="margin-x-auto"
                >
                  Call {rotatedNumber}
                  <br />
                  TTY: 711
                </LinkButton>
                <br />
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <Banner
          backgroundColor="Primary"
          className="no-margin-heading hide-image"
          layout="100"
          centerAligned="true"
          mainContent={
            <>
              <Typography
                variant="h5"
                style={{ color: 'white', marginBottom: '5px' }}
              >
                Aetna Advantage plans with dental, vision, and hearing benefits
                at no additional cost.
              </Typography>
            </>
          }
        />

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <Typography variant="h2">How We Support You</Typography>
            </>
          }
        >
          <Stack>
            <Columns>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-thumbs-up.svg"
                    alt="thumbs-up icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5">Convenience</Typography>
                  <Typography variant="body">
                    We simplify the process by filing the application and
                    paperwork for you.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-dollar-alt.svg"
                    alt="dollar-alt icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5">Compare</Typography>
                  <Typography variant="body">
                    Estimated  price comparisons help simplify the decision
                    making process.
                  </Typography>
                </div>
              </Column>
              <Column className="card center-content">
                <div className="top-content">
                  <StaticImage
                    src="../../images/icons/purple-support.svg"
                    alt="support icon"
                    layout="fixed"
                    className="product-icons"
                  />
                  <Typography variant="h5">Support</Typography>
                  <Typography variant="body">
                    Our licensed agents will provide quality support to aid in
                    your decision making process.
                  </Typography>
                </div>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
